<template>
  <div class="wrapper -calidad-innovacion padding-menu">
    <div class="breadcrumb">
      <div class="container row-end">
        <router-link to="/">Grupo IAN</router-link>
        <router-link to="/" style="pointer-events: none;">{{
          $t("navbar_menu.quality_innovation.title")
        }}</router-link>
        <router-link :to="$route.path">{{
          $t("innovacion.title")
        }}</router-link>
      </div>
    </div>

    <section class="intro-page">
      <div class="container">
        <h1 class="waypoints">{{ $t("innovacion.title") }}</h1>
        <img :src="$t('innovacion.main_banner.img')" alt="" class="waypoints" />
        <div class="green-box">
          <h2>{{ $t("innovacion.main_banner.title") }}</h2>
          <div v-html="$t('innovacion.main_banner.text')"></div>
        </div>
      </div>
    </section>

    <!-- <section class="imagen-texto">
      <div class="container row-start">
        <img :src="$t('innovacion.innovation.img')" class="waypoints" />
        <div class="texto">
          <h2 class="main-title waypoints">
            {{ $t("innovacion.innovation.title") }}
          </h2>
          <div v-html="$t('innovacion.innovation.text')"></div>
        </div>
      </div>
    </section> -->

    <section class="banner-seguridad">
      <div class="container row-start">
        <div class="texto waypoints">
          <h2 class="main-title">
            {{ $t("innovacion.innovation.title") }}
          </h2>
          <div v-html="$t('innovacion.innovation.text')"></div>
        </div>
        <img
          :src="$t('innovacion.security_banner.img1')"
          alt="Innovación"
          class="seguridad-1 waypoints"
        />
        <img
          :src="$t('innovacion.security_banner.img2')"
          alt="Innovación"
          class="seguridad-2 waypoints"
        />
        <img
          :src="$t('innovacion.security_banner.img3')"
          alt="Innovación"
          class="seguridad-3 waypoints"
        />
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "innovacion",
};
</script>

<style lang="scss">
.imagen-texto {
  margin-top: 100px;
}

.banner-seguridad .seguridad-1 {
  margin-right: 100px;
}

@media screen and (max-width: 1040px) {
  .intro-page img {
    width: 100%;
  }
}

@media screen and (max-width: 900px) {
  .banner-seguridad {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .imagen-texto {
    margin-top: 20px;
  }
}
</style>
